<template>
  <div class="footer">
    <div class="footer_top flex just-center">
        <div class="con flex just-between">
            <div class="left">
                <div>{{ dataItem.organizer }}</div>
                <div>{{ dataItem.techSupport }}</div>
            </div>
            <div class="right">
                <div class="title">友情链接</div>
                <div class="link flex items-center just-between">
                    <div>
                        <div class="cursor" v-for="(item,index) in even" :key="index" @click="navigator(item)">{{ item.friendshipLinkName }}</div>
                    </div>
                    <div>
                        <div class="cursor" v-for="(item,index) in odd" :key="index" @click="navigator(item)">{{ item.friendshipLinkName }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer_bottom">
        <div class="ul flex items-center just-center">
            <div class="li flex items-center">
                <img class="img" src="@/assets/phone.png" alt="" style="width:19px;height:21px;margin-right:10px;">
                <div>联系方式：{{ dataItem.telephone }}</div>
            </div>
            <div class="li flex items-center">
                <img class="img" src="@/assets/address.png" alt="" style="width:20px;height:25px;margin-right:10px;">
                <div>地址：{{ dataItem.address }}</div>
            </div>
            <div class="li flex items-center">
                <img class="img" src="@/assets/email.png" alt="" style="width:27px;height:21px;margin-right:10px;">
                <div>邮箱：{{ dataItem.email }}</div>
            </div>
        </div>
        <div class="flex just-center" style="margin-top:15px;">{{ dataItem.icp }}</div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
      return {
        buseUrl:process.env.VUE_APP_BACKEND_URL+'/api/file',
        dataItem:JSON.parse(localStorage.getItem('webSiteData')),
        even:[],//友情链接1
        odd:[],//友情链接2
      };
    },
  
    mounted() {
        let array = this.dataItem.list
        let even = []
        let odd = []
        array.forEach((item,i)=>{
            if (i % 2 == 0){
             even.push(item)
            }else{
             odd.push(item)
            }
        })
        this.even = even
        this.odd = odd
        
    },
  
    methods: {
        navigator(row){//友情链接跳转
            window.open(row.friendshipLink,'_blank')
        },
    },
}
</script>

<style lang="scss">
.footer{width:100%;
    .footer_top{
        width:100%;
        background:url('@/assets/footerBg.png') no-repeat;
        background-size:100% 100%;
        // height:340px;
        .con{
            width:950px;
            color:#fff;
            padding:33px 0 23px;
            .left{
                font-size:30px;
                line-height:70px;
            }
            .right{
                width:300px;
                .title{
                    font-size:30px;
                }
                .link{
                    font-size:20px;
                    margin-top:30px;
                    line-height:45px;
                }
            }
        }
    }
    .footer_bottom{
        width:100%;
        height:103px;
        background: #333333;
        font-size: 20px;
        color: #878787;
        padding-top:17px;
        .ul{
            .li{
                margin:0 30px;
            }
        }
    }
}
</style>