<template>
  <div id="app" class="demo-image__lazy">
    <myHeader v-if="show" />
    <myContact v-if="show" />

    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>

    <router-view v-if="!$route.meta.keepAlive" />
    <myFooter  v-if="show" />
  </div>
</template>

<script>
  import { queryHomepage } from '@/api/home'
  export default {
    data() {
      return {
        show:localStorage.getItem('area')?true:false,
      }
    },
    mounted() {
      if(!this.show){
          localStorage.setItem('area', JSON.stringify({areaCode:'',areaName:''}));
      }
      let area = JSON.parse(localStorage.getItem('area'))?JSON.parse(localStorage.getItem('area')):{areaCode:'',areaName:''}
      this.getHomepage(area)
    },
    
    methods: {
      getHomepage(area){
        queryHomepage(area).then(res=>{
          if(res.status==200){
            this.dataItem = res.data.officialWeb
            localStorage.setItem('webSiteData', JSON.stringify(res.data.officialWeb));
            this.show = true
          }
        })
      },
    },
  };
</script>


<style lang="scss">
.image-slot{
  width:100%;
  height:100%;
  font-size:18px;
  background:#d8d8d8;
  color:#878787;
}
.home {
  padding: 20px 0;
}
.demo-image__lazy{
  overflow: auto;
}
.el-cascader-panel .in-active-path,.el-cascader-panel  .is-active{
  color:#000 !important;
}
.el-pagination .el-pager .active{
  background:#0c4abe !important;
}

*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}
 
html {
  font-size: 10px;
}
/* 鼠标手型 */
.cursor {
  cursor: pointer;
}
/* 文本超出隐藏 */
.text-overflow-one {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  overflow: hidden;
}
/* 兼容IE flex布局 */
.flex {
  display:flex;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  -js-display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
}
.flex-shrink{
  flex-shrink: 0;
}
.flex-wrap {
  flex-wrap: wrap;
}
.items-center {
  align-items: center;
}
.items-end{
  align-items:flex-end;
}
.flex-column{
  flex-flow: column;
}
.align-between{
  align-content: space-between;
}
.just-between {
  justify-content: space-between !important;
}
.just-center {
  justify-content: center;
}
.just-end {
  justify-content: flex-end;
}
.just-start {
  justify-content: flex-start;
}
.just-evenly {
  justify-content: space-evenly;
}
.just-around {
  justify-content: space-around;
}
/* 过度动画 */
.ease {
  transition: all 0.2s ease !important;
}
.ease_3 {
  transition: all 0.3s ease !important;
}
/* 隐藏滚动条 */
::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
  cursor: pointer;
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  cursor: pointer;
}
</style>
